import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import {
  ebayCreateCredentialRequest,
  shopifyCreateCredentialRequest,
  shopifyAuthRequest,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { addEbayShopRequest } from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { authLoginRequest } from 'src/store/dashboard/slices/authSlice';

export default function ShopifyConnectPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const shop = searchParams.get('shop');
    const fromParam = searchParams.get('from');

    if (fromParam === 'install-sp') {
      localStorage.setItem('isShopifyTester', 'true');
      dispatch(shopifyAuthRequest('primelisterTestStoreWithTestData.myshopify.com'));
      return;
    }

    if (code && shop) {
      const decodedCode = decodeURIComponent(code);
      dispatch(shopifyCreateCredentialRequest({ shop, code: decodedCode }));

      // Remove code and shop from URL query params
      searchParams.delete('code');
      searchParams.delete('shop');
      const newUrl = window.location.pathname + '?' + searchParams.toString();
      window.history.replaceState({}, '', newUrl);
      // Redirect after a short delay to show loading state
      setTimeout(() => {
        navigate(PATH_DASHBOARD.inventory.root);
      }, 3000);
    } else if (!fromParam) {
      // If no code is present and not from install-sp, redirect to my shops
      navigate(PATH_DASHBOARD.inventory.root);
    }
  }, []);

  return (
    <Container>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <CircularProgress size={48} />
        <Typography variant="h6">Connecting your Shopify shop...</Typography>
        <Typography color="text.secondary">Please wait while we set up your connection</Typography>
      </Box>
    </Container>
  );
}
