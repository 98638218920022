import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import { ebayCreateCredentialRequest } from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { addEbayShopRequest } from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';

export default function EbayConnectPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Get all search parameters
  const searchParamsObject = Object.fromEntries(searchParams.entries());

  //   useEffect(() => {
  //     const code = searchParams.get('code');
  //     const state = searchParams.get('state');
  //     const isAutomation = state?.toLowerCase().includes('automation');

  //     if (code) {
  //       const decodedCode = decodeURIComponent(code);
  //       if (isAutomation) {
  //         dispatch(addEbayShopRequest({ code: decodedCode }));
  //       } else {
  //         dispatch(ebayCreateCredentialRequest({ code: decodedCode }));
  //       }

  //       // Redirect after a short delay to show loading state
  //       setTimeout(() => {
  //         if (isAutomation) {
  //           navigate(PATH_AUTOMATIONS.ebayMyShops);
  //         } else {
  //           navigate(PATH_DASHBOARD.myShops);
  //         }
  //       }, 3000);
  //     } else {
  //       // If no code is present, redirect to my shops
  //       navigate(PATH_DASHBOARD.myShops);
  //     }
  //   }, []);

  return (
    <Container>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        {/* <CircularProgress size={48} /> */}
        <Typography variant="h6">Connecting your Mercari shop...</Typography>
        <div>
          <Typography variant="subtitle1">Search Parameters:</Typography>
          <pre style={{ background: '#f5f5f5', padding: '1rem', borderRadius: '4px' }}>
            {JSON.stringify(searchParamsObject, null, 2)}
          </pre>
        </div>
        {/* <Typography color="text.secondary">
          Please wait while we set up your connection
        </Typography> */}
      </Box>
    </Container>
  );
}
