// @ts-nocheck
import { Marketplace, EbayCountry } from '@beta.limited/primelister';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  IShopifyProductDetail,
  IAPIShopStoreData,
  IMyShopsSingleDetail,
} from 'src/pages/dashboard/MyShops/types/shopifyTypes';
import { IImportListingItem } from './importTableSlice';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

export enum IAPIMarketplaces {
  EBAY = Marketplace.EBAY_US,
  EBAY_CA = Marketplace.EBAY_CA,
  EBAY_AU = Marketplace.EBAY_AU,
  EBAY_AT = Marketplace.EBAY_AT,
  EBAY_BE = Marketplace.EBAY_BE,
  EBAY_CH = Marketplace.EBAY_CH,
  EBAY_DE = Marketplace.EBAY_DE,
  EBAY_ES = Marketplace.EBAY_ES,
  EBAY_FR = Marketplace.EBAY_FR,
  EBAY_GB = Marketplace.EBAY_GB,
  EBAY_HK = Marketplace.EBAY_HK,
  EBAY_IE = Marketplace.EBAY_IE,
  EBAY_IN = Marketplace.EBAY_IN,
  EBAY_IT = Marketplace.EBAY_IT,
  EBAY_MY = Marketplace.EBAY_MY,
  EBAY_NL = Marketplace.EBAY_NL,
  EBAY_PH = Marketplace.EBAY_PH,
  EBAY_PL = Marketplace.EBAY_PL,
  EBAY_SG = Marketplace.EBAY_SG,
  EBAY_TW = Marketplace.EBAY_TW,
  EBAY_VN = Marketplace.EBAY_VN,
  SHOPIFY = Marketplace.SHOPIFY,
  // ETSY = 'etsy',
}

export const officialApiPlatforms = Object.values(IAPIMarketplaces);

export interface IMarketPlaceProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  shopData: IAPIShopStoreData | null;
  productList: IShopifyProductDetail[] | any[];
  nextPageID: string;
}

const defaultStateForMarketplaces: IMarketPlaceProps = {
  isLoggedIn: false,
  isLoading: false,
  shopData: null,
  productList: [],
  nextPageID: '',
};

export interface OfficialPlatformStates {
  isLoading: boolean;
}

const getCurrentEbayPlatform = (state: Draft<IMyShopsAPITable>, platformName: IAPIMarketplaces) => {
  const currentEbayPlatform = state[platformName as IAPIMarketplaces] as Draft<IMarketPlaceProps>;

  return currentEbayPlatform;
};

// Helper function to create initial state for all eBay marketplaces
const createEbayMarketplacesState = () => {
  const state: { [key in IAPIMarketplaces]?: IMarketPlaceProps } = {};

  // Create state for each eBay marketplace in IAPIMarketplaces
  Object.values(IAPIMarketplaces).forEach((marketplace) => {
    if (marketplace.toLowerCase().includes(AutomationPlatform.EBAY)) {
      state[marketplace] = defaultStateForMarketplaces;
    }
  });

  return state;
};

export interface IMyShopsAPITable {
  error: string;
  loading: boolean;
  isAllPlatformsLoading: boolean;
  APIRowMenuPopoverRef: any;
  allShops: IMyShopsSingleDetail[];
  isLayoutRequestSent: boolean;
  [key in IAPIMarketplaces]?: IMarketPlaceProps;
}

const initialState: IMyShopsAPITable = {
  error: '',
  loading: false,
  isAllPlatformsLoading: false,
  isLayoutRequestSent: false,
  APIRowMenuPopoverRef: null,
  ebayCountryPlatform: '',
  allShops: null,
  ...createEbayMarketplacesState(),
  [Marketplace.SHOPIFY]: defaultStateForMarketplaces,
};

const myShopsAPITableSlice = createSlice({
  name: 'myShopsAPITable',
  initialState,
  reducers: {
    setAllPlatformsInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllPlatformsLoading = action.payload;
      // Update loading state for all eBay marketplaces
      Object.values(IAPIMarketplaces).forEach((marketplace) => {
        if (marketplace.toLowerCase().includes('ebay')) {
          state[marketplace].isLoading = action.payload;
        }
      });
      state[IAPIMarketplaces.SHOPIFY].isLoading = action.payload;
    },
    getAllConnectedApiShopsRequest: (state) => {
      state.loading = true;
      // Update loading state for all eBay marketplaces
      Object.values(IAPIMarketplaces).forEach((marketplace) => {
        if (marketplace.toLowerCase().includes(AutomationPlatform.EBAY)) {
          state[marketplace].isLoading = true;
        }
      });
      state[IAPIMarketplaces.SHOPIFY].isLoading = true;
      state.error = '';
    },
    getAllConnectedApiShopsSuccess: (state, action: PayloadAction<IMyShopsSingleDetail[]>) => {
      state.loading = false;
      // Update loading state for all eBay marketplaces
      Object.values(IAPIMarketplaces).forEach((marketplace) => {
        if (marketplace.toLowerCase().includes(AutomationPlatform.EBAY)) {
          state[marketplace].isLoading = false;
        }
      });
      state[IAPIMarketplaces.SHOPIFY].isLoading = false;
      state.error = '';
      state.allShops = action.payload;
      state.isLayoutRequestSent = true;

      action.payload.forEach((shop) => {
        const matchedPlatform = officialApiPlatforms.find(
          (platform) => platform === shop!.marketplace!.toLowerCase()
        );

        if (matchedPlatform) {
          state[matchedPlatform].isLoggedIn =
            shop.connectionStatus.toLowerCase() === ConnectionStatus.Connected;

          state[matchedPlatform].shopData = {
            ...state[matchedPlatform].shopData,
            shopName: shop.shopName,
            accessToken: shop.accessToken,
            connectionStatus: shop.connectionStatus,
          };
        }
      });
    },
    getAllConnectedApiShopsFail: (state, action: PayloadAction<string>) => {
      state.isLayoutRequestSent = true;
      state.loading = false;
      state.error = action.payload;
    },
    setShopsLoadingState: (state, action: PayloadAction<boolean>) => {},
    //--SHOPIFY-AUTH
    shopifyAuthRequest: (state, action: PayloadAction<string>) => {
      state.shopify.isLoading = true;
      state.error = '';
    },
    shopifyAuthSuccess: (state) => {
      state.shopify.isLoading = false;
      state.error = '';
    },
    shopifyAuthFail: (state, action: PayloadAction<string>) => {
      state.shopify.isLoading = false;
      state.error = action.payload;
    },

    ebayAuthRequest: (state, action: PayloadAction<string>) => {
      state[IAPIMarketplaces.EBAY].isLoading = true;
      state.error = '';
    },
    ebayAuthSuccess: (state) => {
      state[IAPIMarketplaces.EBAY].isLoading = false;
      state.error = '';
    },
    ebayAuthFail: (state, action: PayloadAction<string>) => {
      state[IAPIMarketplaces.EBAY].isLoading = false;
      state.error = action.payload;
    },

    ebayCreateCredentialRequest: (state, action: PayloadAction<{ code: string }>) => {
      state[IAPIMarketplaces.EBAY].isLoading = true;
      state.error = '';
    },
    ebayCreateCredentialSuccess: (state, action: PayloadAction<IAPIShopStoreData>) => {
      const shopData = action.payload;
      state[IAPIMarketplaces.EBAY].isLoading = false;
      state.error = '';

      state[IAPIMarketplaces.EBAY].shopData = shopData;
      const { connectionStatus } = shopData;
      if (connectionStatus.toLowerCase() === ConnectionStatus.Connected) {
        state[IAPIMarketplaces.EBAY].isLoggedIn = true;
      } else {
        state[IAPIMarketplaces.EBAY].isLoggedIn = false;
      }
    },
    ebayCreateCredentialFail: (state, action: PayloadAction<string>) => {
      state[IAPIMarketplaces.EBAY].isLoading = false;

      state[IAPIMarketplaces.EBAY].isLoggedIn = false;
      state.error = action.payload;
    },
    //--EBAY-PRODUCTS
    getEbayProductsRequest: (
      state,
      action: PayloadAction<{
        limit?: number;
        nextPage?: string;
        platformName: IAPIMarketplaces;
        importSearchText: string;
      }>
    ) => {
      const currentEbayPlatform = getCurrentEbayPlatform(state, action.payload.platformName);

      currentEbayPlatform.isLoading = true;

      state.error = '';
      if (action.payload.nextPage) {
        currentEbayPlatform.nextPageID = action.payload.nextPage;
      }
    },
    getEbayProductsSuccess: (
      state,
      action: PayloadAction<{
        listings: IImportListingItem[];
        success: boolean;
        nextPage?: string;
        platformName: IAPIMarketplaces;
      }>
    ) => {
      state.error = '';

      const currentEbayPlatform = getCurrentEbayPlatform(state, action.payload.platformName);

      currentEbayPlatform.productList = [
        ...currentEbayPlatform.productList!,
        ...action.payload.listings,
      ];
    },
    getEbayProductsFail: (
      state,
      action: PayloadAction<{
        message: string;
        platformName: IAPIMarketplaces;
      }>
    ) => {
      const currentEbayPlatform = getCurrentEbayPlatform(state, action.payload.platformName);

      currentEbayPlatform.isLoading = false;
      state.error = action.payload.message;
    },
    //--EBAY-PRODUCT-DETAIL
    getEbayProductDetailRequest: (state, action: PayloadAction<{ id: string }>) => {
      state[IAPIMarketplaces.EBAY].isLoading = true;
      state.error = '';
    },
    getEbayProductDetailSuccess: (state) => {
      state[IAPIMarketplaces.EBAY].isLoading = false;
      state.error = '';
    },
    getEbayProductDetailFail: (state, action: PayloadAction<string>) => {
      state[IAPIMarketplaces.EBAY].isLoading = false;
      state.error = action.payload;
    },

    //--SHOPIFY-CREDENTIAL
    shopifyCreateCredentialRequest: (
      state,
      action: PayloadAction<{ shop: string; code: string }>
    ) => {
      state.shopify.isLoading = true;
      state.error = '';
    },
    shopifyCreateCredentialSuccess: (state, action: PayloadAction<IAPIShopStoreData>) => {
      state.shopify.isLoading = false;
      state.error = '';
      state.shopify.shopData = action.payload;
      const { connectionStatus } = action.payload;
      if (connectionStatus.toLowerCase() === ConnectionStatus.Connected) {
        state.shopify.isLoggedIn = true;
      } else {
        state.shopify.isLoggedIn = false;
      }
    },
    shopifyCreateCredentialFail: (state, action: PayloadAction<string>) => {
      state.shopify.isLoading = false;
      state.error = action.payload;
      state.shopify.isLoggedIn = false;
    },

    //--SHOPIFY-PRODUCTS
    getShopifyProductsRequest: (
      state,
      action: PayloadAction<{ limit: number; nextPage?: string }>
    ) => {
      state.shopify.isLoading = true;
      state.error = '';
      if (action.payload.nextPage) {
        state.shopify.nextPageID = action.payload.nextPage;
      }
    },
    getShopifyProductsSuccess: (
      state,
      action: PayloadAction<{
        listings: IShopifyProductDetail[];
        success: boolean;
        nextPage?: string;
      }>
    ) => {
      state.shopify.isLoading = false;
      state.error = '';
      state.shopify.productList = [...state.shopify.productList!, ...action.payload.listings];
    },
    getShopifyProductsFail: (state, action: PayloadAction<string>) => {
      state.shopify.isLoading = false;
      state.error = action.payload;
    },
    //--SHOPIFY-PRODUCT-DETAIL
    getShopifyProductDetailRequest: (state, action: PayloadAction<{ id: string }>) => {
      state.shopify.isLoading = true;
      state.error = '';
    },
    getShopifyProductDetailSuccess: (state) => {
      state.shopify.isLoading = false;
      state.error = '';
    },
    getShopifyProductDetailFail: (state, action: PayloadAction<string>) => {
      state.shopify.isLoading = false;
      state.error = action.payload;
    },
    resetMyShopsAPITableState: (state) => initialState,
  },
});

export const {
  setAllPlatformsInitialLoading,
  //--All shops
  getAllConnectedApiShopsRequest,
  getAllConnectedApiShopsSuccess,
  getAllConnectedApiShopsFail,
  //--Shopify
  shopifyAuthRequest,
  shopifyAuthSuccess,
  shopifyAuthFail,
  shopifyCreateCredentialRequest,
  shopifyCreateCredentialSuccess,
  shopifyCreateCredentialFail,
  getShopifyProductsRequest,
  getShopifyProductsSuccess,
  getShopifyProductsFail,
  getShopifyProductDetailRequest,
  getShopifyProductDetailSuccess,
  getShopifyProductDetailFail,
  //--Ebay
  ebayAuthRequest,
  ebayAuthSuccess,
  ebayAuthFail,
  ebayCreateCredentialRequest,
  ebayCreateCredentialSuccess,
  ebayCreateCredentialFail,
  getEbayProductDetailRequest,
  getEbayProductDetailFail,
  getEbayProductDetailSuccess,
  getEbayProductsFail,
  getEbayProductsRequest,
  getEbayProductsSuccess,
  resetMyShopsAPITableState,
} = myShopsAPITableSlice.actions;

export default myShopsAPITableSlice.reducer;
