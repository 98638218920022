import { ActionSource } from '@beta.limited/primelister';
import { EXTENSION_ID_V2, IS_SANDBOX } from 'src/config';

export enum BackgroundActionsEnum {
  PING = 'ping',
  CHECK_LOGIN_STATUS = 'checkLoginStatus',
  UPDATE_COOKIES = 'updateCookies',
  COPY = 'copy',
  CROSSLIST = 'crosslist',
  DELIST = 'delist',
  IMPORT = 'import',
  RELIST = 'relist',
  REMOVE = 'remove',
  SEND_OFFER = 'sendOffer',
  UPDATE_ACTIVE_JOBS = 'updateActiveJobs',
  GET_EXTENSION_VERSION = 'getExtensionVersion',
}

export class ExtensionMessageChannel {
  public isConnected = false;

  public messageChannelName = 'PRIMELISTER';

  public events = new EventTarget();

  private checkConnectionInterval: any = null;

  constructor() {
    this.checkConnection();
    this.listenMessages();
  }

  sendMessage(options?: any) {
    const maxRetries = 3;
    const delay = 1000;
    let attempts = 0;

    const browserInfo = {
      userAgent: navigator.userAgent,
      vendor: navigator.vendor,
      platform: navigator.platform,
      documentReadyState: document.readyState,
      timestamp: new Date().toISOString(),
    };

    const attempt = (): Promise<any> =>
      new Promise((resolve, reject) => {
        const isShopifyTester = localStorage.getItem('isShopifyTester') === 'true';
        if (isShopifyTester) return;
        if (!chrome?.runtime?.sendMessage) {
          reject(
            new Error(
              `Chrome runtime is not available\nBrowser Info: ${JSON.stringify(browserInfo)}`
            )
          );
          return;
        }

        chrome.runtime.sendMessage(EXTENSION_ID_V2, options, (response) => {
          if (chrome.runtime.lastError) {
            if (++attempts < maxRetries) {
              this.log(`Retry attempt ${attempts}/${maxRetries}`, 'INFO');
              setTimeout(() => attempt().then(resolve).catch(reject), delay);
              return;
            }
            reject(
              new Error(
                `Failed after ${maxRetries} attempts: ${
                  chrome.runtime.lastError.message
                }\nBrowser Info: ${JSON.stringify(browserInfo)}`
              )
            );
          } else {
            resolve(response);
          }
        });
      });

    return attempt();
  }

  sendCommand(type: BackgroundActionsEnum, options: any = {}): Promise<any> {
    options.actionSource = ActionSource.WEB;
    return new Promise((resolve, reject) => {
      this.sendMessage({ type, options })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  private log(message: string, level: 'INFO' | 'ERROR' = 'INFO'): void {
    const timestamp = new Date().toISOString();
    console.log(`[${level}] ${timestamp}: ${message}`);
  }

  private async listenMessages() {
    window.addEventListener('message', (event) => {
      if (event.source !== window) return;
      if (event.data.type && event.data.type === this.messageChannelName) {
        if (IS_SANDBOX) {
          this.log(`Message received: ${event.data}`);
        }
      }
    });
  }

  private setCheckConnectionInterval(
    milliseconds: number,
    connect: () => void,
    disconnect: () => void
  ) {
    if (this.checkConnectionInterval !== null) {
      clearInterval(this.checkConnectionInterval);
    }
    this.checkConnectionInterval = setInterval(() => {
      this.sendMessage({ type: 'ping' }).then(connect).catch(disconnect);
    }, milliseconds);
  }

  public clearConnectionCheck() {
    if (this.checkConnectionInterval !== null) {
      clearInterval(this.checkConnectionInterval);
      this.checkConnectionInterval = null;
    }
  }

  private async checkConnection() {
    // Define connect and disconnect as arrow functions to keep the correct 'this' context
    const connect = () => {
      if (!this.isConnected) {
        this.isConnected = true;
        this.events.dispatchEvent(new Event('connect'));
        this.setCheckConnectionInterval(10000, connect, disconnect); // Set interval to 10 seconds when connected
      }
    };

    const disconnect = () => {
      this.isConnected = false;
      this.events.dispatchEvent(new Event('disconnect'));
      this.setCheckConnectionInterval(250, connect, disconnect); // Set interval to 250 ms when disconnected
    };

    // Immediately start with a 250 ms interval
    this.setCheckConnectionInterval(250, connect, disconnect);
  }
}
