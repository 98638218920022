import { Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import {
  DataGridCreatedDateColumnInterface,
  IInventoryPopoverData,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import QuickActionsMenu from '../../others/QuickActionsMenu';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../store';
import { getDateWithTimeZone } from '../../../../../../../utils/getDateWithTimezone';

export default function DataGridCreatedDateColumn({
  itemData,
}: DataGridCreatedDateColumnInterface) {
  const addedCell = useRef(null);
  const isShopifyTester = localStorage.getItem('isShopifyTester') === 'true';
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);

  const { added, id, marketplaceSources, title, image } = itemData;

  const isToday = useMemo(() => moment(added).isSame(moment(), 'day'), [added]);
  const newPopoverData = useMemo(
    () => ({ id, marketplaceSources, title, image }),
    [id, marketplaceSources, title, image]
  );

  const formatType = isToday ? 'hh:mm A' : 'DD MMM YYYY';
  const formattedDate = useMemo(
    () => getDateWithTimeZone(added, userAccountDetail?.timeZone?.name, formatType),
    [added, userAccountDetail?.timeZone?.name, formatType]
  );

  const { isInFullscreenMode } = useSelector((state: IRootState) => state.inventory);

  return (
    <Stack
      ref={addedCell}
      direction="row"
      spacing={1}
      sx={{ position: 'relative', height: '100%', alignItems: 'center' }}
    >
      <Tooltip title={moment(added).format('DD MMM YYYY hh:mm A')} placement="top">
        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', textAlign: 'center' }}>
          {formattedDate}
        </Typography>
      </Tooltip>

      {!isShopifyTester && (
        <Stack
          sx={{
            right: !isInFullscreenMode ? '-52px' : '-50px',
            position: 'absolute',
            minHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            zIndex: 99,
          }}
        >
          <QuickActionsMenu newData={newPopoverData as IInventoryPopoverData} />
        </Stack>
      )}
    </Stack>
  );
}
