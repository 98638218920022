import React, { useEffect, useRef } from 'react';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import {
  IMyShopsTableRow,
  setIsAPITableActionsMenuOpen,
  setSelectedActionRow,
} from 'src/store/dashboard/slices/myShopsSlice';
import Iconify from 'src/components/iconify';
import {
  ebayAuthRequest,
  shopifyAuthRequest,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';

const APIRowMenuPopover = () => {
  const dispatch = useDispatch();
  const { isAPITableActionsMenuOpen, selectedActionRow, isOfficialAPIEbay, isOfficialAPIShopify } =
    useSelector((state: IRootState) => state.myShops);

  useEffect(() => {
    // Check if the URL has 'from=install-sp' parameter
    const searchParams = new URLSearchParams(window.location.search);
    const fromParam = searchParams.get('from');

    if (fromParam === 'install-sp') {
      dispatch(shopifyAuthRequest('primelisterTestStoreWithTestData.myshopify.com'));
    }
  }, [selectedActionRow]);

  const handleClose = () => {
    dispatch(setIsAPITableActionsMenuOpen(null));
    dispatch(setSelectedActionRow({} as IMyShopsTableRow));
  };

  const handleConnect = () => {
    if (isOfficialAPIShopify) {
      dispatch(shopifyAuthRequest(selectedActionRow.connectionLink));
    }
    if (isOfficialAPIEbay) {
      const countryCode = selectedActionRow?.platformName?.split('-')[1]; // Example: ebay-ca, ebay-us, ebay-au
      dispatch(ebayAuthRequest(countryCode));
    }
    handleClose();
  };

  const { isConnected } = selectedActionRow;

  // We do not need this for the moment, but we might in the future
  const renderActionMenuOptions = () =>
    !isConnected ? (
      <MenuItem onClick={handleConnect}>
        <Iconify icon="uil:link" /> Connect
      </MenuItem>
    ) : (
      <>
        <MenuItem onClick={handleConnect}>
          <Iconify
            icon="pajamas:retry"
            sx={{ width: '16px !important', height: '16px !important', ml: 0.3 }}
          />
          Re-Connect
        </MenuItem>
      </>
    );

  return (
    <MenuPopover
      open={isAPITableActionsMenuOpen}
      onClose={handleClose}
      arrow="right-top"
      sx={{ minWidth: 140 }}
    >
      {renderActionMenuOptions()}
    </MenuPopover>
  );
};

export default APIRowMenuPopover;
