import { useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridSortModel,
  GridSelectionModel,
  GridFilterModel,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setPage,
  setLoadingEffect,
  inventoryGetListRequest,
  setSortModel,
  setSelectedCheckboxes,
  setInventoryFilterModel,
  setBulkActionsListingList,
  setCrosslistSourceLoginErrorPlatformsBulk,
} from 'src/store/dashboard/slices/inventorySlice';

import { gridColumns } from './Colums';
import TaskFooter from '../others/InventoryFooter';
import InventorToolbar from '../others/InventoryToolbar';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { getGroupsRequest, getTagsRequest } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import EmptyContent from 'src/components/empty-content/EmptyContent';
import { Box, Zoom } from '@mui/material';
import { useListingDataWithSelection } from 'src/hooks/useListingDataWithSelection';
import { useSearchParams } from 'react-router-dom';
import {
  dataGridFilterControlsForRequest,
  queryFilterItemControl,
} from 'src/utils/datagridFilterControlsForRequest';
import { useQueryParamsForDatagrid } from 'src/hooks/useQueryParamsForDatagrid';
import usePlatformLoginStatusCheck from 'src/hooks/usePlatformLoginStatusCheck';
import { checkListingSourcesLoginStatus } from 'src/utils/checkListingSourcesLoginStatus';
import Iconify from 'src/components/iconify';
import { getDisconnectedListings } from './helpers';
import InventoryQuickEditPopover from '../others/InventoryQuickEditPopover';
import { IAPIMarketplaces } from 'src/store/dashboard/slices/myShopsAPITableSlice';

export default function InventoryDataGrid() {
  const dispatch = useDispatch();
  const {
    dense,
    inventory,
    inventoryCount,
    page,
    loadingEffect,
    sortModel,
    searchValue,
    selectedCheckboxes,
    rowsPerPage,
    inventoryFilterModel,
    bulkActionsListingList,
    crosslistSourceLoginErrorPlatforms,
    isInFullscreenMode,
    selectedQuickEditItemType,
    currentSelectedGroup,
    currentSelectedTags,
    currentSelectedStatus,
    quickEditAnchorPosition,
  } = useSelector((state: IRootState) => state.inventory);
  const { tagsData } = useSelector((state: IRootState) => state.groupsAndTags);
  const { getNewlyAddedListings, removeDeselectedListings } = useListingDataWithSelection();
  const [searchParams, setSearchParams] = useSearchParams();

  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  useQueryParamsForDatagrid({
    datagrid: 'inventory',
    searchValue,
    inventoryFilterModel,
    inventoryPage: page,
    inventoryRowsPerPage: rowsPerPage,
    inventorySortModel: sortModel,
  });

  useEffect(() => {
    dispatch(getTagsRequest());
    dispatch(getGroupsRequest());
  }, []);

  // Handles page change and fetchs new data from the API.
  const handlePageChange = (pageNumber: number) => {
    searchParams.set('page', (pageNumber + 1).toString());
    setSearchParams(searchParams);
    dispatch(setPage(pageNumber));
    dispatch(setLoadingEffect(true));
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryGetListRequest({
        page: pageNumber,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(inventoryFilterModel),
        searchValue,
      })
    );
  };

  // Handles the sort model changes and fetchs new data from the API.
  const handleSortModelChange = (model: GridSortModel) => {
    dispatch(setSortModel(model));

    dispatch(setLoadingEffect(true));

    const orderBy = model.length > 0 ? model[0].field : 'added';
    const order = model.length > 0 ? model[0].sort : 'desc';
    searchParams.set('order', order as string);
    searchParams.set('orderBy', orderBy);
    setSearchParams(searchParams);

    dispatch(
      inventoryGetListRequest({
        page,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(inventoryFilterModel),
        searchValue,
      })
    );
  };

  const handleSelectedCheckboxes = (checkboxes: GridSelectionModel): void => {
    const hasNewListings: boolean = checkboxes.length > selectedCheckboxes.length;
    const hasRemovedListings: boolean = checkboxes.length < selectedCheckboxes.length;

    if (hasNewListings) {
      const newlyAddedListings = getNewlyAddedListings(checkboxes);

      const bulkActionsListing = [...newlyAddedListings, ...bulkActionsListingList];
      dispatch(setBulkActionsListingList(bulkActionsListing));

      const { allListingSources, atLeastOneSourceConnected } = checkListingSourcesLoginStatus(
        bulkActionsListing,
        getLoginStatusOfMarketplace
      );

      if (!atLeastOneSourceConnected) {
        const disconnectedListings = getDisconnectedListings(allListingSources);
        dispatch(setCrosslistSourceLoginErrorPlatformsBulk(disconnectedListings));
      }
    }

    if (hasRemovedListings) {
      const filteredListingsState = removeDeselectedListings(checkboxes);
      dispatch(setBulkActionsListingList(filteredListingsState));

      if (crosslistSourceLoginErrorPlatforms.length) {
        const updatedErrorPlatforms = crosslistSourceLoginErrorPlatforms.filter((errorPlatform) =>
          filteredListingsState.some((listing) => listing._id === errorPlatform.listingId)
        );

        dispatch(setCrosslistSourceLoginErrorPlatformsBulk(updatedErrorPlatforms));
      }
    }

    dispatch(setSelectedCheckboxes(checkboxes as Array<string>));
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    dispatch(setInventoryFilterModel(model));
    const queryFilterModel = {
      ...model,
      items: model.items.filter((item) => queryFilterItemControl(item)),
    };

    if (dataGridFilterControlsForRequest(model)) {
      dispatch(setBulkActionsListingList([]));
      dispatch(setSelectedCheckboxes([]));
      dispatch(setPage(0));
      const [sortField, sort] =
        sortModel.length > 0 ? [sortModel[0].field, sortModel[0].sort] : ['added', 'desc'];

      const sortOptions = { orderBy: sortField, order: sort };

      const shouldAddFilterToQuery = model.items.length && queryFilterModel.items.length;
      if (shouldAddFilterToQuery) {
        searchParams.set('filters', JSON.stringify(queryFilterModel));
        setSearchParams(searchParams);
      } else {
        searchParams.delete('filters');
        setSearchParams(searchParams);
      }

      dispatch(
        inventoryGetListRequest({
          page: 0,
          rowsPerPage,
          filter: getFilterModelShaped(model),
          searchValue,
          ...sortOptions,
        })
      );
    } else {
      if (queryFilterModel.items.length) {
        searchParams.set('filters', JSON.stringify(queryFilterModel));
        setSearchParams(searchParams);
      } else {
        searchParams.delete('filters');
        setSearchParams(searchParams);
      }
    }
  };

  const filteredData = useCallback(() => {
    const uniqueInventory = inventory.filter(
      (item, index, self) => self.findIndex((i) => i._id === item._id) === index
    );
    return uniqueInventory;
  }, [inventory, inventoryCount]);

  const dataGridComponentProps: GridSlotsComponentsProps = {
    filterPanel: {
      sx: {
        '& .MuiDataGrid-filterFormValueInput': {
          minWidth: 'max-content !important',
        },
      },
    },
    row: {
      onMouseOver: (event: any) => {
        const currentRow: any = event.currentTarget;
        const id: string = currentRow.getAttribute('data-id');
        const quickMenu = document.querySelector(
          `[data-quick-menu-id="${id}"]`
        ) as HTMLElement | null;
        if (quickMenu) {
          quickMenu.style.display = 'flex';
        }
      },
      onMouseLeave: (event: any) => {
        const currentRow: any = event.currentTarget;
        const id: string = currentRow.getAttribute('data-id');
        const quickMenu = document.querySelector(
          `[data-quick-menu-id="${id}"]`
        ) as HTMLElement | null;

        if (quickMenu) {
          quickMenu.style.display = 'none';
        }
      },
    },
  };

  const dataGridHeight = !isInFullscreenMode ? 'calc(100vh - 174px)' : 'calc(100vh - 32px)';
  return (
    <Box sx={{ height: dataGridHeight }}>
      <DataGridPro
        density={dense ? 'compact' : 'comfortable'}
        loading={loadingEffect}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        page={page}
        pageSize={rowsPerPage}
        getEstimatedRowHeight={() => 500}
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={[25, 50, 100, 250, 500]}
        components={{
          Footer: TaskFooter,
          Toolbar: InventorToolbar,
          NoRowsOverlay: () => <EmptyContent title="No results found." />,
          NoResultsOverlay: () => <EmptyContent title="No results found." />,
          OpenFilterButtonIcon: () => <Iconify icon="solar:filter-bold" width={24} height={24} />,
        }}
        componentsProps={dataGridComponentProps}
        columns={gridColumns(tagsData)}
        rows={filteredData().map((item) => ({
          id: item._id,
          image: item.thumbnail,
          title: { title: item.title, id: item._id },
          sku: item.sku,
          price: item.price,
          marketplaceSources: item.refs,
          refs: item.refs, // We are using this 'refs' in inventory marketplace filter
          groups: item.groups,
          listingTags: item.listingTags,
          status: item.status,
          added: {
            added: item.added,
            marketplaceSources: item.refs,
            refs: item.refs, // We are using this 'refs' in inventory marketplace filter
            id: item._id,
            title: item.title,
            image: item.thumbnail,
          },
        }))}
        rowCount={inventoryCount}
        sortModel={sortModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'added', sort: 'desc' }],
          },
        }}
        headerHeight={dense ? 59 : 43.6}
        selectionModel={selectedCheckboxes}
        filterModel={inventoryFilterModel}
        checkboxSelection={true}
        disableColumnResize={true}
        disableColumnSelector={true}
        disableColumnPinning={true}
        disableSelectionOnClick={true}
        keepNonExistentRowsSelected={true}
        onPageChange={(pageNumber: number) => handlePageChange(pageNumber)}
        onSortModelChange={(model: GridSortModel) => handleSortModelChange(model)}
        onSelectionModelChange={handleSelectedCheckboxes}
        onFilterModelChange={(model: GridFilterModel) => {
          handleFilterModelChange(model);
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordBreak: 'break-word !important',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { px: '3px', py: '0px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { p: '6px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            px: '13px',
            py: '7.5px',
          },
          '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
            ...(selectedCheckboxes.length > 0 && { pb: '96px' }),
          },
        }}
      />
      {selectedQuickEditItemType && (
        <InventoryQuickEditPopover
          anchorPosition={{
            top: quickEditAnchorPosition?.top ?? 0,
            left: quickEditAnchorPosition?.left ?? 0,
          }}
          currentTags={currentSelectedTags || undefined}
          currentGroups={currentSelectedGroup || []}
          currentStatus={currentSelectedStatus || undefined}
        />
      )}
    </Box>
  );
}
