import { useEffect } from 'react';
import axiosInstance from 'src/utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setImportNextPageID,
  setHasMoreImportableListings,
  importGetListingsRequest,
  setIsLoadMoreLoading,
  IImportListingItem,
  setListingsForImport,
  setImportedListingsList,
  setFetchedListings,
  setImportSearchButtonLoading,
  setImportClearButtonLoading,
  resetImportDialogState,
  setSelectedImportCheckboxes,
} from 'src/store/dashboard/slices/importTableSlice';
import { crosslistEndpoints } from 'src/endpoints';
import { IAPIMarketplaces } from 'src/store/dashboard/slices/myShopsAPITableSlice';
import {
  CurrentInventoryActionEnum,
  IGetListingsResponseData,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import store, { IRootState } from 'src/store';
import {
  shapeImportInformationToStringArray,
  shapeListingsForImportCheck,
} from 'src/pages/dashboard/Inventory/helpers/helpersForImportStatusCheck';
import { union } from 'lodash';
import { ServiceTypesEnum } from 'src/pages/dashboard/MyShops/types';
import { setOpenAPIConnectionRequiredDialog } from 'src/store/dashboard/slices/inventorySlice';
import {
  MARKETPLACE_PLATFORMS,
  getShopNameAndId,
  getShopperId,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { Marketplace } from '@beta.limited/primelister';
import { AxiosResponse } from 'axios';

interface FetchDataResult {
  listings: IImportListingItem[];
  hasMore: boolean;
  nextPageID: any;
  importStatusData: any;
}

type FetchListingsParams = {
  hideImported: boolean;
  accumulatedListings: IImportListingItem[];
  pageID: any;
};

export const useImportDataForInventory = (
  officialApiPlatforms: IAPIMarketplaces[],
  isIncludedInLists: (id: string) => boolean
) => {
  const {
    hideImportedItems,
    listingsForImport,
    importedListingsList,
    hasMoreImportableListings,
    importPlatform,
    importSearchText,
    fetchedListings,
    hasActiveSearch,
    currentInventoryAction,
    isOfficialAPIShopify,
    isOfficialAPIEbay,
    selectedImportCheckboxes,
  } = useSelector((state: IRootState) => state.importTable);
  const myShops = useSelector((state: IRootState) => state.myShops);
  const dispatch = useDispatch();

  const fetchListingsData = async (pageID: any) => {
    const { shopId, shopName } = getShopNameAndId(importPlatform as Marketplace, myShops);
    const response: AxiosResponse<IGetListingsResponseData> = await axiosInstance.get(
      crosslistEndpoints.inventory.GET_IMPORT_TABLE_LISTINGS_ENDPOINT({
        marketplace: importPlatform as Marketplace,
        ...(MARKETPLACE_PLATFORMS.includes(importPlatform as Marketplace) && {
          sendWithMarketplace: true,
        }),
        shop: shopName,
        shopId,
        ...(importSearchText && { search: importSearchText }),
        ...(pageID && { page: pageID }),
      })
    );

    return response.data;
  };

  const fetchListingsFromOfficialAPI = async (type: ServiceTypesEnum, pageID?: any) => {
    let response;
    switch (type) {
      case ServiceTypesEnum.SHOPIFY:
        response = await axiosInstance.get(
          crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_PRODUCTS(),
          {
            params: {
              limit: 25,
              ...(pageID && { nextPage: pageID }),
            },
          }
        );
        break;

      case ServiceTypesEnum.EBAY:
        try {
          response = await axiosInstance.get(
            crosslistEndpoints.myshops.ebay.GET_EBAY_API_PRODUCTS(
              pageID ?? 1,
              importSearchText ?? ''
            )
          );
        } catch (error) {
          if (error?.message && typeof error.message === 'string' && error.message.toLowerCase().includes('auth tokens')) {
            dispatch(resetImportDialogState());
            dispatch(setOpenAPIConnectionRequiredDialog(true));
          }
          return {
            listings: [],
            hasMore: false,
            nextPage: null
          };
        }
        break;

      default:
        throw new Error(`Invalid service type: ${type}`);
    }

    return response.data;
  };

  const fetchListings = async (isOfficialApiPlatform: boolean, pageID: any) => {
    if (isOfficialApiPlatform) {
      if (isOfficialAPIShopify) {
        return fetchListingsFromOfficialAPI(ServiceTypesEnum.SHOPIFY, pageID);
      } else if (isOfficialAPIEbay) {
        return fetchListingsFromOfficialAPI(ServiceTypesEnum.EBAY, pageID);
      }
    } else {
      return fetchListingsData(pageID);
    }
  };

  function updateReduxStateForImportData(
    data: IGetListingsResponseData,
    combinedListings: IImportListingItem[],
    importStatusData: any,
    hideImported: boolean
  ): void {
    dispatch(setImportNextPageID(data.nextPage));
    dispatch(setHasMoreImportableListings(data.hasMore));

    if (!hideImported) {
      const uniqueListings = combinedListings.filter(
        (listing) => !listingsForImport.some((existing) => existing.id === listing.id)
      );

      const newListingsForImport = [...listingsForImport, ...uniqueListings];
      dispatch(setListingsForImport(newListingsForImport));

      const updatedImportedListingsList = shapeImportInformationToStringArray(
        importStatusData,
        true
      );
      dispatch(setImportedListingsList([...importedListingsList, ...updatedImportedListingsList]));

      // Update selected checkboxes if all were previously selected
      if (selectedImportCheckboxes.length === listingsForImport.length) {
        const newSelectableListings = newListingsForImport.filter(
          (listing) => !isIncludedInLists(listing.id)
        );
        dispatch(setSelectedImportCheckboxes(newSelectableListings.map((listing) => listing.id)));
      }
    }
  }

  function getListingsToConsider(
    hideImported: boolean,
    importStatusData: any,
    listings: IImportListingItem[]
  ): string[] {
    return hideImported
      ? shapeImportInformationToStringArray(importStatusData.data.data, false)
      : listings.map((listing) => listing.id);
  }

  function filterAndCombineListings(
    listings: IImportListingItem[],
    listingsToConsider: string[],
    accumulatedListings: IImportListingItem[]
  ): IImportListingItem[] {
    const filteredListings = listings.filter((listing) => listingsToConsider.includes(listing.id));
    return [...accumulatedListings, ...filteredListings];
  }

  async function fetchData({
    hideImported,
    accumulatedListings = [],
    pageID = null,
  }: FetchListingsParams): Promise<FetchDataResult> {
    const isOfficialApiPlatform = officialApiPlatforms.includes(
      importPlatform as unknown as IAPIMarketplaces
    );
    const data: IGetListingsResponseData | undefined = await fetchListings(isOfficialApiPlatform, pageID);

    if (!data || !data.listings) {
      console.error('No data or listings returned from fetchListings');
      return {
        listings: [],
        importStatusData: null,
        hasMore: false,
        nextPageID: null,
      };
    }

    const newDataToCheck = shapeListingsForImportCheck(data.listings, importPlatform);
    const importStatusData = await axiosInstance.post(
      crosslistEndpoints.inventory.GET_INITIAL_IMPORT_INFORMATION_OF_LISTINGS(),
      newDataToCheck
    );

    const listingsToConsider = getListingsToConsider(hideImported, importStatusData, data.listings);
    const combinedListings = filterAndCombineListings(
      data.listings,
      listingsToConsider,
      accumulatedListings
    );

    updateReduxStateForImportData(data, combinedListings, importStatusData.data.data, hideImported);

    return {
      listings: combinedListings,
      importStatusData: importStatusData.data.data,
      hasMore: data.hasMore,
      nextPageID: data.nextPage,
    };
  }

  async function handleLoadMore(pageID: any) {
    dispatch(setIsLoadMoreLoading(true));
    let fetchedListingsLocal = [...fetchedListings];

    if (!hideImportedItems && hasMoreImportableListings) {
      await processLoadMoreWithoutHidingImported(pageID);
    } else if (hideImportedItems) {
      await processLoadMoreWithHidingImported(fetchedListingsLocal, pageID);
    }

    dispatch(setIsLoadMoreLoading(false));
  }

  async function processLoadMoreWithoutHidingImported(pageID: any) {
    const { hasMore, nextPageID } = await fetchData({
      hideImported: hideImportedItems,
      accumulatedListings: [],
      pageID,
    });
    dispatch(setHasMoreImportableListings(hasMore));
    dispatch(setImportNextPageID(nextPageID));
  }

  async function processLoadMoreWithHidingImported(
    fetchedListingsLocal: IImportListingItem[],
    pageID: any
  ) {
    let nextPageID = pageID;
    let hasMoreLocal;

    do {
      const response = await fetchData({
        hideImported: true,
        accumulatedListings: fetchedListingsLocal,
        pageID: nextPageID,
      });
      const newFetchedListings = filterListingsNotIncludedOrImported(response.listings);

      fetchedListingsLocal = union(fetchedListingsLocal, newFetchedListings);

      ({ hasMore: hasMoreLocal, nextPageID } = response);
    } while (hasMoreLocal && fetchedListingsLocal.length < 10);

    dispatch(setFetchedListings(fetchedListingsLocal));
    dispatch(setListingsForImport(fetchedListingsLocal));

    // Update selected checkboxes if all were previously selected
    if (selectedImportCheckboxes.length === listingsForImport.length) {
      const newSelectableListings = fetchedListingsLocal.filter(
        (listing) => !isIncludedInLists(listing.id)
      );
      dispatch(setSelectedImportCheckboxes(newSelectableListings.map((listing) => listing.id)));
    }
  }

  function filterListingsNotIncludedOrImported(
    listings: IImportListingItem[]
  ): IImportListingItem[] {
    return listings.filter((listing) => !isIncludedInLists(listing.id));
  }

  const loadMore = async (pageID: any) => {
    await handleLoadMore(pageID);
  };

  const resetInventoryGridListings = () => {
    dispatch(setImportedListingsList([]));
    dispatch(setImportNextPageID(null));
    dispatch(setHasMoreImportableListings(false));
    dispatch(setFetchedListings([]));
  };

  const checkSearchListings = () => {
    if (hasActiveSearch && hideImportedItems) {
      const listingsForActiveSearch = listingsForImport.filter(
        (item) => !isIncludedInLists(item.id)
      );
      dispatch(setListingsForImport(listingsForActiveSearch));
    } else {
      dispatch(setListingsForImport([]));
    }
  };

  const initListings = () => {
    if (!hideImportedItems) {
      if (hasActiveSearch) {
        if (currentInventoryAction === CurrentInventoryActionEnum.SEARCH) {
          setButtonLoadingAndGetListings(setImportSearchButtonLoading);
        } else if (currentInventoryAction === CurrentInventoryActionEnum.CLEAR) {
          setButtonLoadingAndGetListings(setImportClearButtonLoading);
        }
      } else {
        dispatch(
          importGetListingsRequest({
            importPlatform,
            importSearchText,
            myShopsState: myShops,
          })
        );
      }
    } else {
      if (!hasActiveSearch) {
        const pageID: any = store.getState().importTable.importNextPageID;
        loadMore(pageID);
      }
    }
  };

  const setButtonLoadingAndGetListings = (setLoadingFunction: Function) => {
    dispatch(setLoadingFunction(true));
    dispatch(
      importGetListingsRequest({
        myShopsState: myShops,
        importPlatform,
        importSearchText,
      })
    );
    dispatch(setLoadingFunction(false));
  };

  useEffect(() => {
    resetInventoryGridListings();
    checkSearchListings();
    initListings();
  }, [hideImportedItems, hasActiveSearch]);

  return {
    fetchedListings,
    handleLoadMore,
  };
};
