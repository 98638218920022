import { Navigate, ScrollRestoration, useLocation, useRoutes } from 'react-router-dom';
// cookie
import cookie from 'react-cookies';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { IS_SANDBOX, PATH_AFTER_LOGIN } from '../config';
// pages
import HomePage from '../pages/dashboard/Home/HomePage';
import { Page404, LoginPage, SignUpPage } from './elements';
import TasksPage from 'src/pages/dashboard/Tasks/TasksPage';
import AccountPage from 'src/pages/dashboard/Account/AccountPage';
import InventoryPage from 'src/pages/dashboard/Inventory/InventoryPage';
import ResetPasswordPage from 'src/pages/dashboard/Auth/ForgotPassword/ForgotPasswordPage';
import ForgotPasswordPage from 'src/pages/dashboard/Auth/ResetPassword/ResetPasswordPage';
import CrosslistPricingPage from 'src/pages/dashboard/PricingPage/components/crosslistPricing/CrosslistPricingPage';
import PoshmarkAutomationPricingPage from 'src/pages/dashboard/PricingPage/components/poshmarkPricing/PoshmarkAutomationPricingPage';
import EditPage from 'src/pages/dashboard/Inventory/mainComponents/EditPage/EditPage';
import CreatePage from 'src/pages/dashboard/Inventory/mainComponents/CreatePage/CreatePage';

import AutomationsLayout from 'src/layouts/automations/AutomationsLayout';
import AutomationsPage from 'src/pages/automations/AutomationsPage/AutomationsPage';
import MyShopsPage from 'src/pages/dashboard/MyShops/MyShopsPage';
import PaymentSummaryPage from 'src/pages/dashboard/PricingPage/components/paymentSummary/PaymentSummaryPage';
import ActivityPage from 'src/pages/automations/ActivityPage/ActivityPage';
import LoadingScreen from 'src/components/loading-screen';
import MyClosetPage from 'src/pages/automations/MyClosetPage/MyClosetPage';
import ExtensionUninstallPage from 'src/pages/extension/ExtensionUninstallPage';
import ExtensionInstallPage from 'src/pages/extension/ExtensionInstallPage';
import ListingsPage from 'src/pages/automations/MyListingsPage/ListingsPage';
import useResponsive from 'src/hooks/useResponsive';
import { useMediaQuery } from '@mui/material';
import EbayAutomationsLayout from 'src/layouts/ebay-automations/EbayAutomationsLayout';
import { PATH_AUTOMATIONS } from './paths';
import EbayAutomationsPage from 'src/pages/ebay-automations/AutomationsPage/AutomationsPage';
import EbayActivityPage from 'src/pages/ebay-automations/ActivityPage/ActivityPage';
import EbayMyShopsPage from 'src/pages/ebay-automations/MyShopsPage/MyShopsPage';
import EbayConnectPage from 'src/pages/connect/ebay/EbayConnectPage';
import EbayAutomationPricingPage from 'src/pages/dashboard/PricingPage/components/ebayPricing/EbayAutomationPricingPage';
import ShopifyConnectPage from 'src/pages/connect/shopify/ShopifyConnectPage';
import OrdersPage from 'src/pages/dashboard/Orders/OrdersPage';
import MercariConnectPage from 'src/pages/connect/mercari/MercariConnectPage';

// ----------------------------------------------------------------------

export default function Router() {
  const isMobileWeb = useResponsive('down', 'sm');
  const isPrimeListerMobileApp = useResponsive('down', 'sm');

  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <CompactLayout />
            </GuestGuard>
          ),
          children: [
            {
              path: '',
              element: <LoginPage />,
            },
          ],
        },
        {
          path: 'sign-up',
          element: (
            <GuestGuard>
              <CompactLayout />
            </GuestGuard>
          ),
          children: [
            {
              path: '',
              element: <SignUpPage />,
            },
          ],
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <CompactLayout />
            </GuestGuard>
          ),
          children: [
            {
              path: '',
              element: <ResetPasswordPage />,
            },
          ],
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <CompactLayout />
            </GuestGuard>
          ),
          children: [
            {
              path: '',
              element: <ForgotPasswordPage />,
            },
            {
              path: 'recover',
              element: <ForgotPasswordPage />,
            },
          ],
        },
        {
          path: 'uninstall',
          element: <ExtensionUninstallPage />,
        },
        {
          path: 'extension-installed',
          element: <ExtensionInstallPage />,
        },
      ],
    },
    {
      path: 'home',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <HomePage />,
        },
      ],
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <AccountPage />,
        },
      ],
    },
    {
      path: 'subscription',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <CrosslistPricingPage /> },
        { path: 'crosslist', element: <CrosslistPricingPage /> },
        { path: 'payment-summary', element: <PaymentSummaryPage /> },
        {
          path: 'ebay-automation',
          element: (
            <AuthGuard>
              <EbayAutomationsLayout />
            </AuthGuard>
          ),
          children: [{ path: '', element: <EbayAutomationPricingPage /> }],
        },
      ],
    },
    {
      path: 'subscription/poshmark-automation',
      element: (
        <AuthGuard>
          <AutomationsLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <PoshmarkAutomationPricingPage /> }],
    },
    {
      path: 'subscription/ebay-automation',
      element: (
        <AuthGuard>
          <EbayAutomationsLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <EbayAutomationPricingPage /> }],
    },
    {
      path: 'tasks',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <TasksPage />,
        },
      ],
    },
    {
      path: 'orders',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <OrdersPage />,
        },
      ],
    },
    {
      path: 'inventory',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <InventoryPage />,
        },
        {
          path: 'create',
          element: <CreatePage />,
        },
        {
          path: ':id',
          element: <EditPage />,
        },
      ],
    },
    {
      path: 'my-shops',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <MyShopsPage />,
        },
      ],
    },
    {
      path: 'bugreport',
      element: (
        <AuthGuard>
          <LoadingScreen />
        </AuthGuard>
      ),
    },
    {
      path: 'auth/verify',
      element: (
        <AuthGuard>
          <LoadingScreen />
        </AuthGuard>
      ),
    },
    {
      path: '/automations',
      children: [
        { path: '', element: <Navigate to="/automations/poshmark" replace /> },

        // Poshmark routes with PoshmarkLayout
        {
          path: 'poshmark',
          element: (
            <AuthGuard>
              <AutomationsLayout />
            </AuthGuard>
          ),
          children: [
            { path: 'settings', element: <AutomationsPage /> },
            { path: 'user', element: <AccountPage /> },
            { path: 'activity', element: <ActivityPage /> },
            { path: 'my-closet', element: <MyClosetPage /> },
            { path: 'my-listings', element: <ListingsPage /> },
          ],
        },

        // eBay routes with EbayLayout
        {
          path: 'ebay',
          element: (
            <AuthGuard>
              <EbayAutomationsLayout />
            </AuthGuard>
          ),
          children: [
            { path: '', element: <Navigate to="/automations/ebay/my-shops" replace /> },
            { path: 'settings', element: <EbayAutomationsPage /> },
            { path: 'activity', element: <EbayActivityPage /> },
            { path: 'my-shops', element: <EbayMyShopsPage /> },
            { path: 'user', element: <AccountPage /> },
          ],
        },
      ],
    },
    {
      path: 'panel/checkout',
      element: (
        <AuthGuard>
          <LoadingScreen />
        </AuthGuard>
      ),
      children: [
        { path: 'poshmark-plan-2022-monthly', element: <LoadingScreen /> },
        { path: 'poshmark-plan-2022-annual', element: <LoadingScreen /> },
        { path: 'plan-monthly', element: <LoadingScreen /> },
        { path: 'plan-annual', element: <LoadingScreen /> },
        { path: 'crosspost-plan-monthly', element: <LoadingScreen /> },
        { path: 'crosspost-plan-annual', element: <LoadingScreen /> },
      ],
    },
    {
      path: 'connect',
      element: (
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'ebay',
          element: <EbayConnectPage />,
        },
        {
          path: 'mercari',
          element: <MercariConnectPage />,
        },
        {
          path: 'shpfy',
          element: <ShopifyConnectPage />,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    {
      path: '*',
      element: (
        <Navigate
          to="/404"
          replace
          state={{ from: window.location.pathname + window.location.search }}
        />
      ),
    },
  ]);
}
